import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [urlInfo, setUrlInfo] = useState(null);
  const [ipInfo, setIpInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ads, setAds] = useState(null);
  
  const renderAds = () => {
    return (
      <div id="ad-container" style={{ display: "block", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
        <a href="https://www.a2hosting.com/?aid=adflyrich&bid=0a6963ac" target="_top">
          <img src="//affiliates.a2hosting.com/accounts/default1/9u7wabf7le4/0a6963ac.jpg" alt="Faster Websites 2023" title="Faster Websites 2023" width="300" height="250" style={{ border: "0px", marginTop: "10px" }} />
        </a>
        <a href="https://www.a2hosting.com/?aid=adflyrich&bid=0183cdfe" target="_top">
          <img src="//affiliates.a2hosting.com/accounts/default1/9u7wabf7le4/0183cdfe.jpg" alt="" title="" width="300" height="250" style={{ border: "0px", marginTop: "10px" }} />
        </a>
        <a href="https://go.fiverr.com/visit/?bta=237457&nci=17031" target="_top">
          <img src="https://fiverr.ck-cdn.com/tn/serve/?cid=36566952" alt="" title="" width="300" height="250" style={{ border: "0px", marginTop: "10px" }} />
        </a>
        <a href="https://go.fiverr.com/visit/?bta=237457&nci=17021" target="_top">
          <img src="https://fiverr.ck-cdn.com/tn/serve/?cid=31533538" alt="" title="" width="300" height="250" style={{ border: "0px", marginTop: "10px" }} />
        </a>
        <a href="https://www.popads.net/users/refer/2765585" target="_top">
          <img src="http://banners.popads.net/250x250.gif" alt="PopAds.net - The Best Popunder Adnetwork" title="" width="300" height="250" style={{ border: "0px", marginTop: "10px" }} />
        </a>
      </div>
    );
  }

  const parseUrl = (input) => {
    try {
      // Handle different URL formats
      let parsedUrl;
      if (input.startsWith('http://') || input.startsWith('https://')) {
        parsedUrl = new URL(input);
      } else if (input.includes('://')) {
        parsedUrl = new URL(input);
      } else {
        parsedUrl = new URL(`http://${input}`);
      }

      return {
        protocol: parsedUrl.protocol,
        hostname: parsedUrl.hostname,
        port: parsedUrl.port || 'Default',
        pathname: parsedUrl.pathname,
        search: parsedUrl.search,
        hash: parsedUrl.hash,
        origin: parsedUrl.origin,
        host: parsedUrl.host,
        searchParams: Object.fromEntries(parsedUrl.searchParams),
      };
    } catch (error) {
      console.error('Error parsing URL:', error);
      return null;
    }
  };

  const getBrowserInfo = () => {
    return {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.userAgentData?.platform || navigator.platform,
      cookiesEnabled: navigator.cookieEnabled,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      colorDepth: window.screen.colorDepth,
    };
  };

  const fetchIpInfo = async (hostname) => {
    try {
      const response = await fetch(`https://ipapi.co/${hostname}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch IP information');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching IP info:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const info = parseUrl(url);
    if (info) {
      setUrlInfo({ ...info, ...getBrowserInfo() });
      try {
        const ipData = await fetchIpInfo(info.hostname);
        setIpInfo(ipData);
      } catch (error) {
        setError('Failed to fetch additional IP information');
      }
    } else {
      setError('Invalid URL format');
    }
    setLoading(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>The URL Atlas</h1>
        <div className="blog-article">
          <h2>Unveiling the Anatomy of URLs</h2>
          <p>
            Welcome to The URL Atlas, your professional tool for dissecting and understanding the complex structure of URLs. 
            In today's interconnected digital world, URLs (Uniform Resource Locators) serve as the addresses of the internet, 
            guiding us to specific resources across the vast web landscape.
          </p>
          <p>
            Our advanced URL parser tool breaks down these digital coordinates into their core components, 
            providing valuable insights into the architecture of the web. Whether you're a web developer, 
            digital marketer, or IT professional, The URL Atlas offers comprehensive analysis to support your work.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter a URL to explore (e.g., www.example.com)"
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Analyzing...' : 'Analyze URL'}
          </button>
        </form>
        {error && <div className="error">{error}</div>}
        {urlInfo && (
          <div className="url-info">
            <h2>URL Analysis Results:</h2>
            <h3>URL Components:</h3>
            <p><strong>Protocol:</strong> {urlInfo.protocol}</p>
            <p><strong>Hostname:</strong> {urlInfo.hostname}</p>
            <p><strong>Port:</strong> {urlInfo.port}</p>
            <p><strong>Pathname:</strong> {urlInfo.pathname}</p>
            <p><strong>Search:</strong> {urlInfo.search}</p>
            <p><strong>Hash:</strong> {urlInfo.hash}</p>
            <p><strong>Origin:</strong> {urlInfo.origin}</p>
            <p><strong>Host:</strong> {urlInfo.host}</p>
            <h3>Search Parameters:</h3>
            <ul>
              {Object.entries(urlInfo.searchParams).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
            <h3>Browser Information:</h3>
            <p><strong>User Agent:</strong> {urlInfo.userAgent}</p>
            <p><strong>Language:</strong> {urlInfo.language}</p>
            <p><strong>Platform:</strong> {urlInfo.platform}</p>
            <p><strong>Cookies Enabled:</strong> {urlInfo.cookiesEnabled.toString()}</p>
            <p><strong>Screen Resolution:</strong> {urlInfo.screenResolution}</p>
            <p><strong>Color Depth:</strong> {urlInfo.colorDepth}</p>
          </div>
        )}
        {ipInfo && (
          <div className="ip-info">
            <h2>Additional IP Information:</h2>
            <p><strong>IP:</strong> {ipInfo.ip}</p>
            <p><strong>City:</strong> {ipInfo.city}</p>
            <p><strong>Region:</strong> {ipInfo.region}</p>
            <p><strong>Country:</strong> {ipInfo.country_name}</p>
            <p><strong>ISP:</strong> {ipInfo.org}</p>
            <p><strong>Latitude:</strong> {ipInfo.latitude}</p>
            <p><strong>Longitude:</strong> {ipInfo.longitude}</p>
          </div>
        )}
        {renderAds()}
      </header>
      
    </div>
  );
}

export default App;
